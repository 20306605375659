@import "tailwindcss/base";

@import "tailwindcss/components";
@import "tailwindcss/utilities";


.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}
.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  background-color: #edf2f7;
}

section {
  a {
    @apply underline;
  }
  ul {
    @apply list-disc ml-8;
  }
}
map {
  area {
    cursor: default;
    @apply outline-none;
  }
}